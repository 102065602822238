@import '_variables';
@import '_mixins';

body {
    padding-top: 16rem;
}

.table {
    display: table;
    width: calc(100% + 30px);
    
    [class*="col"] {
        display: table-cell;
        vertical-align: middle; 
        float: none;   
    }
}


// Aniamtions no-touch
.no-touch {
    header {
       .header-top {
            a {
                @include transition(all .3s ease);
            }
           
            a:hover {
               opacity: 0.7;
            }
       } 
    }
}


// Header
header {
    @include transition(all .3s ease);
    
    .header-top {
        padding: 2.2rem 5rem;
        @include transition(all .3s ease);
        position: relative;
        z-index: 1;
        background: $white;
		
		.logo {
			max-width: 280px;
			display: inline-block;
		}
        
        nav {
            margin-right: 2rem;
        }
        
        .search {
            margin-right: 1.5rem;
            
            .search-box {
                height: 9.8rem;
                
                form {
                    .search-input {
                        margin-left: 18rem;
                    }
                }
            }
        }
        
        .website {
            display: inline-block;
            vertical-align: middle;
        }
		
		.site {
			span {
				display: block;
				color: $couleur-2;
				
				.icon {
					display: inline-block;
					vertical-align: middle;
					margin-left: 0.5rem;
				}
			}
		}
    }
    
    [class*="col-md-"]:nth-child(3) {
        svg {
            max-width: 19.5rem;
            height: 3.2rem;
        }
    }
    
    #menuToggle {
        input {
            &:checked ~ .menu-burger {
                @include transform(translate(0, 0));
            }
        }
        
        .menu-burger {
            width: 35rem;
            padding: 8rem 4rem;
        }
    }
    
    .header-bottom {
        @include transition(all .3s ease);
        border-bottom: 0.1rem solid $couleur-4;
        
        nav {
            ul {
                display: table;
                margin: 0 auto;
                
                li {
                    display: table-cell;
                    @include transition(all .3s ease);
                    border-bottom: 0.4rem solid $white;
                    
                    a {
                        color: $couleur-2;
                        font-weight: $bold;
                        padding: 2rem 1.5rem 1.6rem;
                        display: block;
                    }
                    
                    &.item-video {
                        padding-left: 3rem;
                        
                        a {
                            background: $couleur-1;
                            padding: 0.2rem 1rem;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    
    &.scroll {
        @include box-shadow(0 0 20px 0 rgba(0,0,0,0.10));
        
        .header-top {
            padding: 1.2rem 5rem;
        }
        
        .header-bottom {
            opacity: 0;
            visibility: hidden;
            max-height: 0;
            overflow: hidden;
            @include transition(max-height .3s ease);
        }
        
        .search {
            .search-box {
                height: 7.9rem;
            }
        }
    }
}

.category {
    nav {
        li {
            &.topic-blue-steel {
                &.current-menu-item {
                    border-bottom-color: rgba($measurement, 1);
                }
            }
            
            &.topic-purple {
                &.current-menu-item {
                    border-bottom-color: rgba($palatability, 1);
                }
            }
            
            &.topic-green {
                &.current-menu-item {
                    border-bottom-color: rgba($pet-food-protection, 1);
                } 
            }
            
            &.topic-red {
                &.current-menu-item {
                    border-bottom-color: rgba($rouge, 1);
                }
            }
            
            &.topic-blue {
                &.current-menu-item {
                    border-bottom-color: rgba($health-nutrition, 1);
                }
            }
            
            &.topic-pink {
                &.current-menu-item {
                    border-bottom-color: rgba($scent-care, 1);
                }
            }
			
			&.topic-mountain-lake {
				&.current-menu-item {
					border-bottom-color: rgba($sustainability, 1);
				}
			}
        }
    }
}

.single {
    &.blue-steel {
        nav .topic-blue-steel {
            border-bottom-color: rgba($measurement, 1);
        }
    }
    
    &.purple {
        nav .topic-purple {
            border-bottom-color: rgba($palatability, 1);
        }
    }
    
    &.green {
        nav .topic-green {
            border-bottom-color: rgba($pet-food-protection, 1);
        }
    }
    
    &.red {
        nav .topic-red {
            border-bottom-color: rgba($rouge, 1);
        }
    }
    
    &.blue {
        nav .topic-blue {
            border-bottom-color: rgba($health-nutrition, 1);
        }
    }
    
    &.pink {
        nav .topic-pink {
            border-bottom-color: rgba($scent-care, 1);
        }
    }
	
	&.mountain-lake {
		nav .topic-mountain-lake {
			border-bottom-color: rgba($sustainability, 1);
		}
	}
}


.no-touch {
    .header-bottom {
        nav {
            li {
                &:hover {
                    border-bottom-width: 0.4rem;
                    border-bottom-style: solid;
                    
                    a {
                        opacity: 0.8;
                    }
                }
                
                &.topic-blue-steel {
                    &:hover {
                        border-bottom-color: rgba($measurement, 1);
                    }
                }
                
                &.topic-purple {
                    &:hover {
                        border-bottom-color: rgba($palatability, 1);
                    }
                }
                
                &.topic-green {
                    &:hover {
                        border-bottom-color: rgba($pet-food-protection, 1);
                    } 
                }
                
                &.topic-red {
                    &:hover {
                        border-bottom-color: rgba($rouge, 1);
                    }
                }
                
                &.topic-blue {
                    &:hover {
                        border-bottom-color: rgba($health-nutrition, 1);
                    }
                }
                
                &.topic-pink {
                    &:hover {
                        border-bottom-color: rgba($scent-care, 1);
                    }
                }
				
				&.topic-mountain-lake {
					&:hover {
						border-bottom-color: rgba($sustainability, 1);
					}
				}
            }
        }
    }
}


// Slider
.slider {
	max-height: 60rem;
    margin-bottom: 8rem;
    article {
        max-height: 60rem;
        
        .photo {
            max-height: 60rem;
        }
    }
    
    .owl-carousel {
    }
    
    .content{
        width: 80%;
        bottom: 4rem;
        
        p {
            color: rgba($white, 0.7);
            font-size: $font-size-medium;
            font-weight: $light;
            margin-top: 0.5rem;
            font-family: $font-family-2;
        }
    }
    
    .owl-nav {
        button {
            &.owl-prev {
                left: 5rem;
            }
            
            &.owl-next {
                right: 5rem;
            }
        }
    }
}



// Article

.post {
    margin-bottom: 8rem;
}




// Sidebar

aside {
	.title-plugin {
		font-size: 3rem;
		line-height: 3.2rem;
		margin-bottom: 3rem;
	}
	
	.glossary-widget {
		background: $marron;
		padding: 2.5rem;
		position: relative;
		
		&::after {
			clear: both;
			content: "";
			display: block;
		}
		
		&::before {
			content: "Abc";
			font-family: $font-family-2;
			font-size: 16rem;
			color: $white;
			opacity: 0.1;
			position: absolute;
			bottom: -3px;
		    left: 7px;
		    line-height: 110px;
		}
		
		.title-plugin {
			color: $white;
		}
		
		.infos {
			color: $white;
			font-size: 1.7rem;
			line-height: 2.2rem;
			font-family: $font-family-2;
			margin: 1.8rem 0 .5rem;
		}
		
		a {
			z-index: 1;
		    position: relative;
		    float: right;
		}
	}
	
	.tags,
	.experts,
	.publications {
    	background: $marron-tres-light;
		border-top:.2rem solid rgba(94,81,77,0.1);
		padding:4rem 2.5rem;
	}
	
	
	.tags {
		.title-plugin {
			color: $rouge;
		}

		ul {
			li {
				counter-increment: step-counter;
				margin-bottom: 1rem;
				&::before {
					content: counter(step-counter)".";
					color: $rouge;
					margin-right: .5rem;
					font-weight: 700;
				}
				
				a {
					color:$marron;
					
					&::after {
        				display: none;
    				}
				}
			}
		}
	}
	
	.experts {
    	.post-experts {
        	padding: 0.5rem 0;
    	}
    	
		.owl-nav {
			button {
				position: absolute;
				top:20%;
			}
			.owl-prev {
				left:0;
				&:hover {
					left:-.5rem;
				}
			}
			.owl-next {
				right:0;
				&:hover {
					right:-.5rem;
				}
			}
		}
	}
	
	.publications {
		img {
			border-width:.4rem;
			border-style: solid; 
		}
		.title-publi {
			font-size: 2rem;
			font-weight: $bold;
		}
		p {
			font-family: $font-family-2;
			font-size: $font-size-base;
			margin: 1.6rem 0 2rem;
		}
	}
}


// PAGE CATEGORIE


.category-hero {
	height: 54rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	display: table;
	width: 100%;
	margin-bottom: 5rem;
	
	.container {
		position: relative;
		z-index: 2;
		display: table-cell;
		vertical-align: bottom;
		.title-cat {
			display: table;
			padding: .5rem 1rem;
			color: $white;
			margin: auto;
			font-weight: 700;
			margin-bottom: 2.5rem;
		}
		.desc-cat {
			margin-bottom: 5rem;
			p {
				color: $white;
				text-align: center;
				font-weight: 700;
				font-size: 4rem;
			}
		}
	}
}


.title-cat-page {
	font-size: 3.5rem;
	font-weight: $light;
	margin-bottom: 3rem;
}


// Post - SINGLE
.single {
	.hero-post {
		margin-top: 4rem;
		h1 {
			font-size: 4rem;
			padding: 0 3rem;
			margin-bottom: 1rem;
		}
		p {
			font-size: 2rem;
			padding: 0 10rem;
		}
		img {
			margin: 2rem 0 3.5rem;
		}
	}
	
	.sidebar {
    	position: absolute;
        height: 100%;
        
    	.infos-post {
    		.date {
    			margin-bottom: 1.8rem;
    		}
    		.tags {
    			padding-bottom: 2rem;
    			margin-bottom: 2rem;
    		}
    		.like-it {
    			padding-bottom: 2rem;
    			margin-bottom: 2rem;
    			p {
    				span {
    					display: block;
    					font-size: 3rem;
    				}
    			}
    		}
    		
    		&.sticky {
            	position: fixed;
            	top: 15rem;
        	}
    	}
	}
	
	.post {
		margin-bottom: 6rem;
	}
	.post-content {
		h2 {
			font-size: 2.5rem;
			line-height: 3rem;
		}
		blockquote {
			margin: 3rem 0;
			padding: 3rem 4rem;
		}
		ul, ol {
			li {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}
		.gallery {
			clear: both;
			display: table;
			margin: 2rem auto !important;
			.gallery-item {
				.gallery-icon {
					margin: .5rem;
				}
				img {
					border: none !important;
				}
			}
		}
	}
	
	.like-article {
		padding-top: 4rem;
	}
	
	.title-part {
		margin-bottom: 3rem;
		font-size: 2.5rem;
	}
	
	.related {
		margin-bottom: 8rem;
		padding-top: 3rem;
		margin-top: 4rem;
		
		article {
			padding: 2rem;
		}
		
		.col-md-12 {
    		.row .post {
        		width: 33.33333%;
        		
        		&:nth-child(2n+1) {
            		clear: none;
        		}
        		
        		&:nth-child(3n+1) {
            		clear: left;
        		}
    		}
		}
	}
	
}


// Archives experts


.filter {
	margin-bottom: 4rem;
	.form-filter {
		padding: 1.3rem 3rem;
		label {
			font-size: 2rem;
			margin-right: 2rem;
			padding: .4rem 0;
		}
		select {
			height: 3.5rem;
			width: 15rem;
			font-size: 1.4rem;
		}
	}
}

.company-name-cat {
	font-size: 2rem;
	margin-bottom: 2.5rem;
}


// List experts page 

.list-experts {
    .sep-experts {
    	margin: 5rem 0 3rem;
    	
    	.col-md-4:nth-child(3n+1) {
            clear: left;
        }
    }
    
    .post-experts {
        margin-bottom: 8rem;
    }
}


// Single expert

.hero-expert {
	margin-bottom: 5rem;
	h1 {
		font-size: 3.5rem;
		margin-bottom: 4rem;
		span {
			font-size: 2rem;
			padding-left: 1.5rem;
		}
	}
}

.categories {
	margin-bottom: 2.5rem;
	li {
		padding: .5rem 1rem;
		margin-right: 1rem;
	}
}

.single-expert {
    .detail-expert {
        position: relative;
        
        .infos-expert {
            position: absolute;
            top:0;
            left: 0;
            
            .share-expert {
        	    margin-bottom: 5rem;
        	    p {
        		    margin-bottom: 1rem;
        	    }
        	    ul {
        		    li {
        			    margin-left: -.5rem;
        			    &:last-child {
        				    margin-left: 1rem;
        			    }
        			    a {
        					font-size: 3rem;
        				}
        		    }
        	    }
            }
        }
    }
    
}


.form-contact {
	padding: 5rem;
	margin-bottom: 6.5rem;
	.titre-form-contact {
		margin-bottom: 2.5rem;
		.circle {
			margin-right: 1rem;
		}
		p {
			line-height: 4rem;
			font-size:3.5rem;
		}
	}
	form {
		.infos {
			font-size: 1.8rem;
			line-height: 2.4rem;
			margin-bottom: 6rem;
		}
		label {
			line-height: 2.8rem;
			font-size: 1.4rem;
		}
		input[type="text"],
		input[type="email"] {
			height: 5rem;
			margin-bottom: 2rem;
			font-size: 1.6rem;
			padding: 1rem;
		}
		select  {
			height: 5rem;
			margin-bottom: 2rem;
			font-size: 1.6rem;
			padding: 1rem;
		}
		textarea {
			height: 15rem;
			font-size: 1.6rem;
			padding: 1rem;
			margin-bottom: 2rem;
		}
	}
}


// Post linked expert aside
.content-expert-article {
	margin-bottom: 2rem;
	padding: 2.5rem;
	background: $marron-tres-light;
	
	.title-plugin {
		margin-bottom: 3.5rem;
	}
	
	.expert-article {
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		border-bottom: .1rem solid $marron-light;
		
		.topic {
			margin-bottom: 1rem;
			margin-right: .4rem;
			display: inline-block;
			padding: 0.3rem 1rem;
			font-size: $font-size-small;
		}
		
		.title-article-expert {
			font-size: 2rem;	
			line-height: 2.5rem;
			font-weight: $bold;
		}
		
		&:last-child {
			border:none;
			margin: 0;
			padding: 0;
		}
	}
}




.share-post {
	p {
		margin-bottom: 1rem;
	}
	ul {
		li {
			margin-left: -.5rem;
			a {
				font-size: 3rem;
			}
		}
	}
}




// LES EXPERTS CATEGORY

.expert-categorie {
	padding: 4rem;
	margin-bottom: 6rem;
}



// PAGE INDEX

.page-type {
	h1 {
		font-size: 3.5rem;
	    margin-bottom: 3rem;
	}
	
	article {
		margin-bottom: 10rem;
		img {
			margin-bottom: 7rem;
		}
		h2 {
			font-size: 4rem;
		    margin-bottom: 1rem;
		}
		p {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		.image_complementaire {
			margin-top: 9rem;
			position: relative;
			img {
				margin-bottom: 0;
			}
			.legende {
				background: $rouge;
				display: table;
				color:$white;
				max-width: 70%;
				padding: 3rem;
				position: absolute;
				bottom: -5rem;
				left:2.5rem;
			}
		}
        
		form {		
			label {
			    line-height: 2.8rem;
			    font-size: 1.4rem;
			}
			input[type="text"], 
			input[type="email"] {
			    height: 5rem;
			    margin-bottom: 2rem;
			    font-size: 1.4rem;
			    padding: 1rem;
			}
			textarea {
			    height: 15rem;
			    font-size: 1.4rem;
			    padding: 1rem;
			    margin-bottom: 2rem;
			}
			select {
				height: 5rem;
			    margin-bottom: 2rem;
			    font-size: 1.4rem;
			    padding: 1rem;
			}
			.infos-rgpd {
			    font-size: 1.4rem;
			    margin: 2rem 0 4rem;
			    a {
					color: $couleur-1;
				}
			}
			#group-503 {
				margin-top: 3rem;
			}
		}
	}
}


// Footer
footer { 
    nav {
        li {
            float: left;
            
            &:not(:last-child) {
                margin-right: 3rem;
            }
        }
    }
    
    .right {
        text-align: right;
    }
}